import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: () => import('../views/Home')
},{
  path: '/login',
  name: 'Login',
  component: () => import('../views/Login')
},
//   {
//   path: '/register',
//   name: 'Register',
//   component: () => import('../views/Register')
// },
  {
  path: '/resetPassword',
  name: 'ResetPasswordPage',
  component: () => import('../views/ResetPasswordPage')
},{
  path: '/orgList',
  name: 'OrgListPage',
  component: () => import('../views/OrgListPage')
},{
  path: '/orgEdit',
  component: () => import('../views/OrgEditPage')
},{
  path: '/userList',
  component: () => import('../views/UserListPage')
},{
  path: '/userEdit',
  component: () => import('../views/UserEditPage')
},{
  path: '/userRole',
  component: () => import('../views/UserRolePage')
},{
  path: '/roleList',
  component: () => import('../views/RoleListPage')
},{
  path: '/roleEdit',
  component: () => import('../views/RoleEditPage')
},
  // {
//   path: '/examQuestionList',
//   component: () => import('../views/ExamQuestionListPage')
// },{
//   path: '/examQuestionEdit',
//   component: () => import('../views/ExamQuestionEditPage')
// },{
//   path: '/examPaperList',
//   component: () => import('../views/ExamPaperListPage')
// },{
//   path: '/examPaperEdit',
//   component: () => import('../views/ExamPaperEditPage')
// },{
//   path: '/examPlanList',
//   component: () => import('../views/ExamPlanListPage')
// },{
//   path: '/examPlanEdit',
//   component: () => import('../views/ExamPlanEditPage')
// }, {
//   path: '/examPaperPreview',
//   component: () => import('../views/ExamPaperPreviewPage')
// }, {
//   path: '/examPaperView',
//   component: () => import('../views/ExamPaperViewPage')
// }, {
//   path: '/examContest',
//   component: () => import('../views/ExamContestPage')
// }, {
//   path: '/currentExamList',
//   component: () => import('../views/CurrentExamListPage')
// }, {
//   path: '/myContests',
//   component: () => import('../views/MyContestsPage')
// }, {
//   path: '/examQuestionImport',
//   component: () => import('../views/ExamQuestionImportPage')
// }, {
//   path: '/examAnalysis',
//   component: () => import('../views/ExamAnalysisPage')
// }, {
//   path: '/modifyPass',
//   component: () => import('../views/ModifyPassPage')
// }, {
//   path: '/errorQuestionList',
//   component: () => import('../views/ErrorQuestionListPage')
// }, {
//   path: '/areaManager',
//   component: () => import('../views/AreaManagerPage')
// }, {
//   path: '/areaOrgList',
//   component: () => import('../views/AreaOrgListPage')
// }, {
//   path: '/faqList',
//   component: () => import('../views/FaqListPage')
// }, {
//   path: '/contestsList',
//   component: () => import('../views/ContestsListPage')
// }, {
//   path: '/notExamUsers',
//   component: () => import('../views/NotExamUserListPage')
// },
  {
  path: '/gas-meter-in-list',
  component: () => import('../views/gasmeter/GasMeterInListPage')
}, {
  path: '/gas-meter-in-edit',
  component: () => import('../views/gasmeter/GasMeterInEditPage')
}, {
  path: '/requirement-list',
  component: () => import('../views/requirement/RequirementListPage')
}, {
  path: '/work-list',
  component: () => import('../views/work/WorkListPage.vue')
}, {
  path: '/work-edit',
  component: () => import('../views/work/WorkEditPage.vue')
}]

const router = new VueRouter({
  //history地址不带#号，默认hash模式带#号
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
