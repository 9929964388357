import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './css/main.scss'
import 'font-awesome/css/font-awesome.min.css'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false;
//默认修改element-ui的组件大小
let elementUiSize = 'small';
if(window.screen.width > 1366) {
  elementUiSize = 'medium';
}
Vue.use(ElementUI, {size: elementUiSize});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//避免在readonly输入框中按backspace回退
window.onkeydown = (e) =>{
  if(e.key != 'Backspace') {
    return true;
  }
  let el = e.target;
  if(!el) {
    return false;
  }
  let readonly = el.getAttribute("readonly");
  if(readonly !== undefined && readonly !== null && readonly !== 'false') {
    console.log("ban backspace");
    return false;
  }
  let tagName = el.tagName.toLowerCase();
  if(tagName == "textarea" || tagName == 'input') {
    return true;
  }
  return false;
}