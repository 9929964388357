<template>
  <el-aside :width="menuCollapse ? '64px' : '260px'" class="aside-menu-bar">
    <div class="title">
      <el-image :src="require('../../assets/logo.svg')" fit="contain"></el-image>
      <span v-if="!menuCollapse">{{ globalConfig.title }}</span>
    </div>
    <el-menu :default-active="activeIndex" class="menubar"
             background-color="#2b85e4"
             text-color="#ffffff"
             active-text-color="#ff9900"
             :unique-opened="true"
             :collapse="menuCollapse"
             @select="handleSelect">
      <template v-for="menu in menus">
        <el-menu-item v-if="!menu.children || !menu.children.length" :index="menu.menuId">
          <i :class="menu.iconClass + ' fa-fw'" v-if="!!menu.iconClass"></i>
          <span style="font-weight: bold">
            {{menu.menuName}}
          </span>
        </el-menu-item>
        <el-submenu v-if="menu.children && menu.children.length > 0" :index="menu.menuId" :key="menu.menuId">
          <template slot="title">
            <i :class="menu.iconClass + ' fa-fw'" v-if="!!menu.iconClass"></i>
            <span style="font-weight: bold">{{menu.menuName}}</span>
          </template>
          <template v-for="child in menu.children">
            <el-menu-item class="aside-children-menu" v-if="!child.children || !child.children.length" :index="child.menuId">
              <i :class="child.iconClass + ' fa-fw'" v-if="!!child.iconClass"></i>
              <span>{{child.menuName}}</span>
            </el-menu-item>
            <el-submenu class="aside-children-menu" v-if="child.children && child.children.length > 0" :index="child.menuId" :key="child.menuId">
              <template slot="title">
                <i :class="child.iconClass + ' fa-fw'" v-if="!!child.iconClass"></i>
                <span>{{child.menuName}}</span>
              </template>
              <el-menu-item v-for="gChild in child.children" :key="gChild.menuId" :index="gChild.menuId">
                <i :class="gChild.iconClass + ' fa-fw'" v-if="!!gChild.iconClass"></i>
                <span>{{gChild.menuName}}</span>
              </el-menu-item>
            </el-submenu>
          </template>
        </el-submenu>
      </template>
    </el-menu>
  </el-aside>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";

export default {
  name: "AsideMenuBar",
  extends: BaseVue,
  props: {
    menuCollapse: {
      default: false
    }
  },
  data() {
    return {
      activeIndex: '1',
      expanded: false,
      menus: []
    }
  },
  created() {
    this.getMenus().then((dr)=>{
      this.menus = dr.rows;
    });
  },

  methods: {
    handleSelect(menuId, menuIds) {
      let menus = [];
      let menu = null;
      let children = this.menus;
      menuIds.forEach((id) => {
        for (let i in children) {
          if(id == children[i].menuId) {
            menu = children[i];
            break;
          }
        }
        menus.push({
          menuId: menu.menuId,
          menuName: menu.menuName,
          url: menu.url
        });
        children = menu.children;
      });
      console.log(menus);
      this.$emit("on-menu-change", menus);
    }
  }
}
</script>

<style lang="scss">
@import "src/css/variables";

$title-height: 60px;
//背景色
$menu-bg-color: #2b85e4;
//默认字体颜色
$menu-text-color: #fff;
//选中字体颜色
$menu-active-text-color: #2b85e4;

.aside-menu-bar {
  padding: 0px;
  width: 100%;
  height: 100vh;
  color: $menu-text-color;
  background-color: $menu-bg-color;
  overflow: hidden;
  .title{
    width: 100%;
    color: #ededed;
    height: $title-height;
    line-height: $title-height;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      flex-grow: 1;
      font-size: 18px;
      font-weight: bold;
      padding-left: 7px;
    }
    .el-image {
      margin-left: 9px;
      width: 42px;
      height: 100%;
    }
  }
  .el-menu.menubar{
    border-right: 0px;
    height: calc(100vh - 60px);
    overflow: auto;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 0px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 0px;
    }
    .el-menu-item i,
    .el-submenu__title i{
      color: #ffffff;
    }
  }
}
.el-menu--popup {
  .el-menu-item.aside-children-menu i,
  .el-submenu.aside-children-menu .el-submenu__title i{
    color: #ffffff;
  }
}
</style>