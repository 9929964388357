import axios from "axios";
import * as querystring from "querystringify"
import {sm3, sm4} from "sm-crypto";
import {questionErrorTypeEnum, questionTypeEnum} from "@/enums";
//跨域访问
axios.defaults.withCredentials = true

const SM_KEY = "329sjdkfjasjf933";
const SM_IV = "k23kaskdfj3230dj";

let API_ROOT = "";
let FRONT_ROOT = "";
if(process.env.NODE_ENV == 'development') {
    //开发环境
    API_ROOT = "http://localhost:8081"
    FRONT_ROOT = document.location.href.split("#")[0]
    //生产环境
    // API_ROOT = '/api'
    // FRONT_ROOT = document.location.href.split("#")[0].replace("exam-ui/", "");
}else{
    //生产环境
    API_ROOT = '/api'
    FRONT_ROOT = document.location.href.split("#")[0].replace("exam-ui/", "");
}

export default {
    data() {
        return {
            loading: false,
            //全局配置
            globalConfig: Object.assign({
                apiRoot: API_ROOT,
                frontRoot: FRONT_ROOT
            },window.globalConfig),
            //是否移动端
            isMobilePhone: this.testMobilePhone()
        }
    },
    methods: {
        /**
         * 发送post请求
         * @param jsJson
         * @param url
         * @param params
         * @param showInfo
         * @returns {boolean}
         */
        post(isJson, url, params, showInfo) {
            return new Promise((resolve, reject) => {
                let p = params;
                if(!isJson) {
                    p = querystring.stringify(params||{});
                }
                axios.post(this.globalConfig.apiRoot+url,p,{
                    headers:{
                    'Content-type': isJson?'application/json;charset=UTF-8':'application/x-www-form-urlencoded;charset=UTF-8'
                    }
                }).then((rep)=>{
                    let dr = rep.data;
                    if(dr.success) {
                        if(showInfo === true && dr.msg){
                            this.$message.success({
                                message: dr.msg,
                                duration: 1500,
                                offset: 60
                            });
                        }
                        resolve(dr);
                    } else if(dr.code == 405){//session失效
                        //创建一个error405的div，用于只显示一次session失效的通知
                        let error405 = document.getElementById("error405");
                        if(error405) {
                            return;
                        }
                        error405 = document.createElement("div");
                        error405.setAttribute("id", "error405");
                        document.body.append(error405);
                        setTimeout(()=>{
                            error405.remove();
                        },200);

                        this.$message.error(dr.msg);
                        this.$router.push({
                            path: "/login",
                            query: this.$route.query
                        });

                    } else {
                        if(showInfo === true){
                            this.$message.error(dr.msg);
                        }
                        reject(dr);
                    }
                }).catch((e)=>{
                    console.error(e);
                    if(showInfo === true){
                        this.$message.error(e.message);
                    }
                    reject({
                        success: false,
                        msg: "请求发生错误：" + e
                    });
                });
            });
        },
        /**
         * 提交form数据到js服务器
         * @param url
         * @param params
         * @param showInfo
         * @returns {boolean}
         */
        postForm(url, params, showInfo)  {
            return this.post(false, url, params, showInfo);
        },
        /**
         * 提交json数据到服务器
         * @param url
         * @param params
         * @param showInfo
         * @returns {boolean}
         */
        postJson(url, params, showInfo)  {
            return this.post(true, url, params, showInfo);
        },
        /**
         * 获取菜单
         */
        getMenus(systemType) {
            // return this.postForm(systemType == 'admin' ? "/menu/adminMenus" : "/menu/userMenus");
            return this.postForm("/menu/adminMenus");
        },
        /**
         * 获取数据源列表
         * @returns {boolean}
         */
        getDatasource() {
            return this.postForm("/datasource/list-all", {}, true);
        },
        /**
         * 获取代码表
         * @param codeType
         * @param parentCode
         * @returns {boolean}
         */
        getCodes(codeType, parentCode) {
            return this.postForm("/code-item/list", {
                codeType: codeType,
                parentCode: parentCode || null
            }, true);
        },
        //获取第几个字母
        getLetter(index) {
          let letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          return letters.charAt(index);
        },
        //获取中文数字
        getNumberCn(index) {
            let chineseNumbers = ['一','二','三','四','五','六','七','八','九','十'];
            return chineseNumbers[index];
        },
        //获取问题类型中文名
        getQuestionTypeCn(questionType) {
            return questionTypeEnum[questionType];
        },
        //获取错误类型
        getQuestionErrorTypeCn(questionType) {
            return questionErrorTypeEnum[questionType] || '未选择';
        },

        /**
         * SM3签名
         * @param str
         * @protected
         */
        encryptSm3(str) {
            return sm3(sm3(str)+sm3(SM_IV));
        },

        /**
         * SM4加密
         * @param str
         * @protected
         */
        encryptSm4(str) {
            return sm4.encrypt(str, this.stringToBytes(SM_KEY), {mode: 'cbc', padding:'pkcs#5', iv: this.stringToBytes(SM_IV)})
        },

        stringToBytes(str) {
            let ch, st, re = [];
            for (let i = 0; i < str.length; i++ ) {
                ch = str.charCodeAt(i);  // get char
                st = [];                 // set up "stack"

                do {
                    st.push( ch & 0xFF );  // push byte to stack
                    ch = ch >> 8;          // shift value down by 1 byte
                }

                while ( ch );
                // add stack contents to result
                // done because chars have "wrong" endianness
                re = re.concat( st.reverse() );
            }
            // return an array of bytes
            return re;
        },
        //判断是否移动端
        testMobilePhone() {
            const ua = navigator.userAgent.toLowerCase();
            return /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(
                ua
            ) ;
        },
        //日期格式转化
        formatDate(date) {
            let m = date.getMonth()+1;
            if(m < 10) {
                m = "0" + m;
            }
            let d = date.getDate();
            if(d < 10) {
                d = "0" + d;
            }
            return date.getFullYear()+"-"+m+"-"+d;
        }
    }
}
