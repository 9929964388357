<template>
  <div id="app">
    <el-container v-if="globalConfig.layout == 'top'">
      <el-header v-if="!noMenus" height="70px">
        <header-menu-bar @menu-change="onMenuChange"></header-menu-bar>
      </el-header>
      <el-main :class="!noMenus ? 'top-mode' : ''">
        <router-view/>
      </el-main>
    </el-container>
    <el-container v-if="globalConfig.layout == 'left'">
      <aside-menu-bar :menu-collapse="menuCollapse"
                      v-if="!noMenus"></aside-menu-bar>
      <el-container>
        <el-header height="32px">
          <aside-top-bar :menu-collapse="menuCollapse"
                         @on-menu-collapse="menuCollapse = !menuCollapse"
                         v-if="!noMenus"></aside-top-bar>
        </el-header>
        <el-main :class="!noMenus ? 'aside-mode' : ''">
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import HeaderMenuBar from "@/components/base/HeaderMenuBar";
import BaseVue from "@/components/base/BaseVue";
import AsideMenuBar from "@/components/base/AsideMenuBar";
import AsideTopBar from "@/components/base/AsideTopBar";

export default {
  extends: BaseVue,
  components: {AsideTopBar, AsideMenuBar, HeaderMenuBar},
  data() {
    return {
      //菜单是否收起
      menuCollapse: false
    }
  },
  created() {
  },
  methods: {
    onMenuChange(menus) {
      let menu = menus.pop();
      if(menu.url) {
        this.$router.push(menu.url).catch(()=>{});
      }
    }
  },
  computed: {
    noMenus() {
      return this.$route.name == 'Login' || this.$route.name == 'Register' || this.$route.name == 'ResetPasswordPage';
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  .el-header {
    padding: 0px;
  }

  .el-main {
    padding: 0px;
  }

  .el-main.top-mode {
    height: calc(100vh - 70px);
  }

  .el-main.aside-mode {
    height: calc(100vh - 32px);
  }
}
</style>