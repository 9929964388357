<template>
  <div class="header-menu-bar">
    <el-image class="logo" fit="contain" :src="require('../../assets/logo.svg')"></el-image>
    <div class="title">{{ globalConfig.title }}</div>
    <el-menu :default-active="activeIndex" class="menubar" mode="horizontal"
             text-color="#555555"
             @select="handleSelect">
      <template v-for="menu in menus">
        <el-menu-item v-if="!menu.children || !menu.children.length" :index="menu.menuId">
          <i :class="menu.iconClass +' fa fa-fw'" v-if="!!menu.iconClass"></i>
          <span>{{menu.menuName}}</span>
        </el-menu-item>
        <el-submenu v-if="menu.children && menu.children.length > 0" :index="menu.menuId" :key="menu.menuId">
          <template slot="title">
            <i :class="menu.iconClass +' fa fa-fw'" v-if="!!menu.iconClass"></i>
            <span>{{menu.menuName}}</span>
          </template>
          <template v-for="child in menu.children">
            <el-menu-item v-if="!child.children || !child.children.length" :index="child.menuId">
              <i :class="child.iconClass + ' fa fa-fw'" v-if="!!child.iconClass"></i>
              <span>{{child.menuName}}</span>
            </el-menu-item>
            <el-submenu v-if="child.children && child.children.length > 0" :index="child.menuId" :key="child.menuId">
              <template slot="title">
                <i :class="child.iconClass + ' fa fa-fw'" v-if="!!child.iconClass"></i>
                <span>{{child.menuName}}</span>
              </template>
              <el-menu-item v-for="gChild in child.children" :key="gChild.menuId" :index="gChild.menuId">
                <i :class="gChild.iconClass + ' fa fa-fw'" v-if="!!gChild.iconClass"></i>
                <span>{{gChild.menuName}}</span>
              </el-menu-item>
            </el-submenu>
          </template>
        </el-submenu>
      </template>
    </el-menu>
    <div class="user-bar">
<!--      <el-button type="default" class="switch" @click="switchMode" v-if="systemType == 'admin'">-->
<!--        <i class="fa fa-exchange"></i>-->
<!--        切换到用户端-->
<!--      </el-button>-->
<!--      <el-button type="default" class="switch" @click="switchMode" v-if="systemType == 'user' && isAdmin">-->
<!--        <i class="fa fa-exchange"></i>-->
<!--        切换到管理端-->
<!--      </el-button>-->
      <el-avatar size="small" src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"></el-avatar>
      <el-dropdown size="small" style="cursor: pointer">
        <span class="el-dropdown-link">
          {{user.userName||'loading...'}}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="modifyPass">
            <i class="fa fa-pencil-square-o"></i>
            修改密码
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="quit()">
            <i class="fa fa-mail-reply"></i>
            退出
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import Store from "@/utils/Store";

export default {
  name: "HeaderMenuBar",
  extends: BaseVue,

  data() {
    return {
      activeIndex: '0',
      //当前登录用户
      user: {},
      menus: [],
      //是否管理员账户
      isAdmin: false,
      systemType: null
    }
  },
  created() {
    this.systemType = Store.get("systemType");
    this.postForm("/user/me").then(dr=> {
      this.user = dr.bean;
      let roles = this.user.roles;
      for (let role of roles) {
        if (this.user.superAdmin || role.roleCode == 'admin' || role.roleCode == 'org_admin' || role.roleCode == 'area_admin') {
          this.isAdmin = true;
          break;
        }
      }
      if(!this.isAdmin) {
        this.systemType = 'user';
        Store.set("systemType", this.systemType);
      }else if (this.isAdmin && !this.systemType) {
        this.systemType = 'admin';
        Store.set("systemType", this.systemType);
      }
      this.loadMenus();
      if(this.$route.path == '/') {
        this.setDefaultHome();
      }
    });
  },

  methods: {
    loadMenus() {
      this.getMenus(this.systemType).then(dr => {
        this.menus = dr.rows;
      })
    },
    handleSelect(menuId, menuIds) {
      let menus = [];
      let menu = null;
      let children = this.menus;
      menuIds.forEach((id) => {
        for (let i in children) {
          if(id == children[i].menuId) {
            menu = children[i];
            break;
          }
        }
        children = menu.children;
        menus.push({
          menuId: menu.menuId,
          menuName: menu.menuName,
          url: menu.url
        });
      });
      this.$emit("menu-change", menus);
    },
    quit() {
      this.$confirm("确定要退出吗？", "系统提示", {type: "info"}).then(()=>{
        this.postForm("/login/logout");
        this.$router.push("/login");
      })
    },
    modifyPass() {
      this.$router.push("/modifyPass");
    },
    switchMode() {
      if(this.systemType == 'admin') {
        this.systemType = 'user';
      } else {
        this.systemType = 'admin';
      }
      Store.set("systemType", this.systemType);
      this.setDefaultHome();
      this.loadMenus();
    },
    //设置默认首页
    setDefaultHome() {
      // if (this.systemType == 'user') {
      //   this.$router.push("/currentExamList");
      // } else {
      //   this.$router.push("/examQuestionList")
      // }
    }
  }


}
</script>

<style lang="scss" scoped>
@import "../../css/variables";
.header-menu-bar {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-border-primary;
  .logo{
    width: 48px;
    margin: 0px 20px;
  }
  .title{
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
  }
  .menubar{
    flex-grow: 1;
    border-bottom: 0px;
    .el-menu-item i,.el-submenu__title i{
      margin-right: 5px;
    }
  }
  .user-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
    .switch {
      margin-right: 50px;
      font-size: 14px;
      color: $color-font;
    }
    .switch:hover{
      color: $color-primary;
    }
    .el-avatar{
      margin-right: 10px;
    }
  }
}
</style>