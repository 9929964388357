/**
 * 排名方式
 * @type {{avg: string, max: string, sum: string}}
 */
export const scoreRuleEnum = {
    "max": "最高分排名",
    "sum": "总分排名",
    "avg": "平均分排名"
}

/**
 * 题型
 * @type {{single: string, multiple: string, judge: string}}
 */
export const questionTypeEnum = {
    "single": "单选题",
    "multiple": "多选题",
    "judge": "判断题"
}

/**
 * 错误类型
 * @type {{"1": string, "2": string, "3": string, "4": string}}
 */
export const questionErrorTypeEnum = {
    "1": "标准答案错误",
    "2": "题干内容或选项错误",
    "3": "题干内容或选项有歧义",
    "4": "答题解释有误",
    "5": "我答对了，但系统判定错误"
}

/**
 * 常用校验表达式
 * @type {{}}
 */
export const regexEnum = {
    //身份证
    idCard:  /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
    //手机号
    mobile: /^[1][3-9][0-9]{9}$/,
    //email
    email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
    //密码复杂度
    password: /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/,
    //用户名
    userName: /^[a-zA-Z0-9_-]{4,16}$/
}

export const validatorEnum = {
    idCard: (rule, value, callback) => { regexValidatorFunction(rule, value, callback, regexEnum.idCard, "身份证号格式错误")},
    mobile: (rule, value, callback) => { regexValidatorFunction(rule, value, callback, regexEnum.mobile, "手机号格式错误")},
    email: (rule, value, callback) => { regexValidatorFunction(rule, value, callback, regexEnum.email, "邮箱格式错误")},
    password: (rule, value, callback) => { regexValidatorFunction(rule, value, callback, regexEnum.password, "密码必须大于6位，且由字母、下划线、数字等组成")},
    userName: (rule, value, callback) => { regexValidatorFunction(rule, value, callback, regexEnum.userName, "用户名格式必须4位以上，且由字母+数字组成")},
}

//自定义正值表达式校验方法
function regexValidatorFunction(rule, value, callback, regex, message) {
    console.log(value)
    console.log(regex)
    if(!value || regex.test(value)) {
        return callback();
    } else {
        return callback(new Error(rule.message || message));
    }
}