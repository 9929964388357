//本地存储封装
const KEY_USER = "user"
let Store = {
    //设置用户
    setUser(user){
        this.setObject(KEY_USER, user);
    },
    //获取用户
    getUser() {
        return this.getObject(KEY_USER)
    },
    //清除用户
    clearUser() {
        this.clear(KEY_USER)
    },
    setObject(k, v) {
        localStorage.setItem(k, JSON.stringify(v))
    },
    getObject(k) {
        let str = localStorage.getItem(k)
        if(!str) return null;
        return JSON.parse(str);
    },
    set(k, v){
        localStorage.setItem(k, v);
    },
    get(k) {
        return localStorage.getItem(k)
    },
    clear(k) {
        localStorage.removeItem(k);
    }
}

export default Store;
