<template>
  <div class="aside-top-bar">
    <i class="fa fa-angle-double-left toggle-menu" v-if="!menuCollapse" title="收起" @click="toggleMenu"></i>
    <i class="fa fa-angle-double-right toggle-menu" v-if="menuCollapse" title="展开" @click="toggleMenu"></i>
    <i class="fa fa-home" title="主页"></i>
    <el-tabs v-model="activeMenu" closable>
      <el-tab-pane label="用户管理" name="first"></el-tab-pane>
      <el-tab-pane label="配置管理" name="second"></el-tab-pane>
      <el-tab-pane label="角色管理" name="third"></el-tab-pane>
      <el-tab-pane label="定时任务补偿" name="fourth"></el-tab-pane>
      <el-tab-pane label="用户管理" name="first1"></el-tab-pane>
      <el-tab-pane label="配置管理" name="second1"></el-tab-pane>
      <el-tab-pane label="角色管理" name="third1"></el-tab-pane>
      <el-tab-pane label="定时任务补偿" name="fourth1"></el-tab-pane>
      <el-tab-pane label="用户管理" name="first2"></el-tab-pane>
      <el-tab-pane label="配置管理" name="second2"></el-tab-pane>
      <el-tab-pane label="角色管理" name="third2"></el-tab-pane>
      <el-tab-pane label="定时任务补偿" name="fourth2"></el-tab-pane>
    </el-tabs>
    <i class="fa fa-times-circle-o" title="关闭所有"></i>
    <div class="user-bar">
      <el-avatar size="small" src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"></el-avatar>
      <el-dropdown size="small" style="cursor: pointer">
        <span class="el-dropdown-link">
          朱忠南
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <i class="fa fa-pencil-square-o"></i>
            修改密码
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="quit()">
            <i class="fa fa-mail-reply"></i>
            退出
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";

export default {
  name: "AsideTopBar",
  extends: BaseVue,
  props: {
    menuCollapse: {
      default: false
    }
  },
  data() {
    return {
      activeMenu: 'first'
    }
  },
  methods: {
    quit() {
      this.$confirm("确定要退出吗？", "系统提示", {type:'info'}).then(()=>{
        this.$router.push("/login");
      })
    },
    toggleMenu() {
      this.$emit("on-menu-collapse");
    }
  }
}
</script>

<style lang="scss">
@import "src/css/variables";
.aside-top-bar {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid #e6e6e6;
  .toggle-menu{
    padding: 0px 10px;
    cursor: pointer;
  }
  .fa-home {
    cursor: pointer;
    color:$color-error;
    padding: 0px 20px 0px 10px;
  }
  .fa-times-circle-o {
    cursor: pointer;
    padding: 0px 10px;
  }
  .el-tabs{
    width: calc(100vh - 200px);
    flex-grow: 1;
    &__header {
      margin-bottom: 0px;
    }
    &__item {
      height: 30px;
      line-height: 30px;
      border-bottom: 0px;
    }
    &__active-bar {
      height: 1px;
    }
    &__nav-wrap::after {
      height: 0px;
    }
    &__nav-next, &__nav-prev{
      line-height: 32px;
    }
  }
  .user-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
    .el-avatar {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
}
</style>